import { render, staticRenderFns } from "./event.vue?vue&type=template&id=f18a5766&scoped=true"
import script from "./event.vue?vue&type=script&lang=js"
export * from "./event.vue?vue&type=script&lang=js"
import style0 from "./event.vue?vue&type=style&index=0&id=f18a5766&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f18a5766",
  null
  
)

export default component.exports